import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Header } from './header';
import { SortType } from './sort-type';

@Component({
    selector: 'column-header-component',
    templateUrl: './column-header.component.html',
    styleUrls: ['./column-header.component.scss'],
    standalone: false
})
export class ColumnHeaderComponent implements OnInit {
  @Input() header: Header;
  @Output() sortColumn: EventEmitter<Header> = new EventEmitter<Header>();

  constructor() {}

  ngOnInit(): void {}

  sort() {
    if (!this.header.sortable) return;

    if (
      this.header.sortType === SortType.Default ||
      this.header.sortType === SortType.Up
    ) {
      this.header.sortType = SortType.Down;
    } else if (this.header.sortType === SortType.Down) {
      this.header.sortType = SortType.Up;
    }

    this.sortColumn.emit(this.header);
  }

  getSortIcon(type: SortType): string {
    if (type === SortType.Up) return 'arrow_drop_up';
    else if (type === SortType.Down) return 'arrow_drop_down';
    else {
      return 'unfold_more';
    }
  }
}
