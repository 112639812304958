import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-button',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.scss'],
    standalone: false
})
export class ButtonComponent implements OnInit {
  @Input() text: string = '';
  @Input() color:
    | 'primary'
    | 'accent-empty'
    | 'light-primary'
    | 'primary-empty' = 'primary';
  @Input() clickEvent: (args: any) => void;
  @Input() isLoading: boolean = false;
  @Input() form : string = "";
  @Input() type :  "button" | "submit" = "button";
  @Input() disabled: boolean = false;
  @Output() btnClick = new EventEmitter();
  @Input() filterCount : number | undefined = undefined;
  bClass: string = '';
  tClass: string = '';
  spinnerColor: string = 'primary';

  constructor() {}

  ngOnInit(): void {
    this.setupColors();
  }

  setupColors() {
    if (this.color === 'primary-empty') {
      this.bClass =
        'primary-empty bg-primary-contrast text-primary border-primary';
      this.tClass = 'text-accent';
      return;
    }

    if (this.color === 'accent-empty') {
      this.bClass =
        'accent-empty bg-primary-contrast text-accent border-accent';
      this.tClass = 'text-primary';
      return;
    }

    if (this.color === 'light-primary') {
      this.bClass = 'bg-primary-A400';
      this.tClass = 'text-primary-contrast';
      return;
    }

    if (this.color === 'primary') {
      this.spinnerColor = 'accent';
    }

    this.bClass = 'bg-primary border-primary';
    this.tClass = 'text-primary-contrast';
  }

  onClick() {
    if (this.isLoading) return;
    this.btnClick.emit();
  }
}
