import { MenuColumnComponent } from './menu-column.component';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { CallbackColumnComponent } from './callback-column.component';
import { Column } from './column';
import { ColumnElement } from './column-element';
import { ColumnComponent } from './column.component';
import { ColumnDirective } from './column.directive';
import { ColumnType } from './column.type';
import { DateColumnComponent } from './date-column.component';
import { LinkColumnComponent } from './link-column.component';
import { ProgressColumnComponent } from './progress-column.component';
import { TextColumnComponent } from './text-column.component';
import { OrderReportSelectColumnComponent } from './report-columns/order-report-select-column.component';
import { IconColumnComponent } from './icon-column.component';
import { AssetReportSelectColumnComponent } from './report-columns/asset-report-select-column.component';

@Component({
    selector: 'column-container-component',
    template: `
    <div class="column-container" style="text-align:left;">
      <ng-template columnHost></ng-template>
    </div>
  `,
    standalone: false
})
export class ColumnContainerComponent implements OnInit {
  @Input() column: Column;
  @Input() data: any;

  @ViewChild(ColumnDirective, { static: true }) columnHost!: ColumnDirective;

  columnElement: ColumnElement;

  ngOnInit(): void {
    this.createColumnElement();
    this.loadColumnElementComponent();
  }

  createColumnElement() {
    this.columnElement = new ColumnElement(
      this.getComponent(this.column.type),
      {
        data: this.data,
        params: this.column.params,
        fieldName: this.column.fieldName,
      }
    );
  }

  getComponent(type: ColumnType): any {
    if (type === ColumnType.Date) return DateColumnComponent;
    else if (type === ColumnType.Progress) return ProgressColumnComponent;
    else if (type === ColumnType.Link) return LinkColumnComponent;
    else if (type === ColumnType.Callback) return CallbackColumnComponent;
    else if (type === ColumnType.OrderReportSelect)
      return OrderReportSelectColumnComponent;
    else if (type === ColumnType.AssetReportSelect)
      return AssetReportSelectColumnComponent;
    else if (type === ColumnType.Menu) return MenuColumnComponent;
    else if (type === ColumnType.Icon) return IconColumnComponent;

    return TextColumnComponent;
  }

  loadColumnElementComponent() {
    const viewContainerRef = this.columnHost.viewContainerRef;
    viewContainerRef.clear();

    const componentRef = viewContainerRef.createComponent<ColumnComponent>(
      this.columnElement.component
    );
    componentRef.instance.params = this.columnElement.params;
  }
}
