import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-input-dialog',
    templateUrl: './input-dialog.component.html',
    styleUrls: ['./input-dialog.component.scss'],
    standalone: false
})
export class InputDialogComponent implements OnInit {
  formGroup: FormGroup;
  constructor(
    public dialogRef: MatDialogRef<InputDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public inputSettings: InputSettings
  ) {}

  ngOnInit() {
    this.formGroup = new FormGroup({
      formControl: this.inputSettings.formControl,
    });
  }

  onApplyButtop() {
    if (this.formGroup.valid) {
      this.dialogRef.close(this.inputSettings.formControl);
    }
  }
}

export interface InputSettings {
  title: string;
  message: string;
  formControl: FormControl;
}
