import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { CustomSelectOption } from '../custom-select/models/custom-select-option';
import { FormControl, Validators } from '@angular/forms';
import * as uuid from 'uuid';
import { Subject, of, scheduled } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
@Component({
    selector: 'app-custom-multi-select',
    templateUrl: './custom-multi-select.component.html',
    styleUrls: ['./custom-multi-select.component.scss'],
    standalone: false
})
export class CustomMultiSelectComponent implements OnInit {
  searchInput = new Subject();

  protected opt: any[];
  @Input('options') options: CustomSelectOption[] = [];
  @Input('formControl') formControl: FormControl;
  @Output() onItemSelect = new EventEmitter<any>();
  @Output() onSelectAll = new EventEmitter<any[]>();
  @Input('placeholder') placeholder: string = ' ';
  @Input('label') label: string;
  @Input('id') id: string = uuid.v4();
  @Input('disabled') disabled: boolean = false;
  @Input('singleSelection') singleSelection: boolean = false;
  @Input() required: boolean = false;
  @Input('position') position: 'absolute' | 'relative' = 'absolute';
  @Input('heightList') heightList: number = 100;
  @Input('showCheckboxes') showCheckboxes: boolean = true;
  @Input('closeDropDownOnSelection') closeDropDownOnSelection: boolean = false;
  @Output() onChange = new EventEmitter<any[]>();

  dropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'value',
    textField: 'text',
    selectAllText: 'Select All',
    unSelectAllText: 'Unselect All',
    allowSearchFilter: true,
    itemsShowLimit: 1,
    showSelectedItemsAtTop: true,
  };
  constructor() {
    this.searchInput.pipe(debounceTime(1)).subscribe(() => {
      this.setCheckboxes();
    });
  }
  ngAfterViewInit() {
    this.setStyleConfiguration();
  }
  setStyleConfiguration() {
    let multiSelect = document.getElementById(this.id) as Element;
    let observer = new IntersectionObserver((entry, obs) => {
      let target = entry[0].target;
      if (target !== null) {
        let item2 = target.querySelector('.item2') as HTMLElement;
        if (this.position === 'relative') {
          let dropdownList = target.querySelector(
            '.dropdown-list'
          ) as HTMLElement;
          item2.style.position = this.position;
          dropdownList.style.position = this.position;
        }
        item2.style.maxHeight = this.heightList + 'px';
      }
    });

    observer.observe(multiSelect);
  }
  ngOnInit() {
    if (this.formControl.hasValidator(Validators.required)) {
      this.required = true;
    }
    this.dropdownSettings.singleSelection = this.singleSelection;
    this.dropdownSettings.closeDropDownOnSelection =
      this.closeDropDownOnSelection;
  }
  itemSelect(item: any) {
    this.onItemSelect.emit(item);
  }
  selectAll(items: any[]) {
    this.onSelectAll.emit(items);
  }
  change(items: any[]) {
    this.setCheckboxes();
    this.onChange.emit(items);
  }
  setCheckboxes() {
    (
      document.querySelectorAll(
        '.multiselect-item-checkbox [type=checkbox]'
      ) as NodeListOf<HTMLElement>
    )?.forEach((e) => {
      if (!this.showCheckboxes) {
        e.remove();
      }
    });
  }
  onClick() {
    this.setCheckboxes();
  }
  onFilterChange() {
    this.searchInput.next({});
  }
  ngOnDestroy() {
    this.searchInput.complete();
  }
}
