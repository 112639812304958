import { Component, ElementRef, Input, OnInit, AfterViewInit } from '@angular/core';
import { ColumnElementParams } from './column-element-params';

import { ColumnComponent } from './column.component';

export interface MenuConfig {
  name:string;
  eventName:string;
  onInit?:(data:any, values:MenuConfig)=>MenuConfig;
  onClick?:(data:any)=>void;
}

@Component({
    templateUrl: './menu-column.component.html',
    standalone: false
})
export class MenuColumnComponent implements ColumnComponent, OnInit {
  @Input() params: ColumnElementParams;
  menuArray:Array<MenuConfig> = [];
  constructor(private elementRef: ElementRef){}

  ngOnInit(): void {
    let arrayParams = this.params.params as Array<MenuConfig>;
    arrayParams.forEach(element=>{
      let copy = Object.assign({}, element);
      if(copy.onInit) {
        copy = copy?.onInit(this.params.data, element);
      }
      this.menuArray.push(copy);
    });
  }


  onClick(menuConfig:MenuConfig):void{
    if(menuConfig.onClick) {
      menuConfig.onClick(this.params);
    }
  }
}
