import { FormControl, Validators } from '@angular/forms';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
@Component({
    selector: 'column-header-check-box-component',
    templateUrl: './column-header-check-box.html',
    styleUrls: ['./column-header-check-box.scss'],
    standalone: false
})
export class ColumHeaderCheckBoxComponent implements OnInit {

  @Output() changed = new EventEmitter<boolean>();

  protected formContorl = new FormControl<boolean>(false)

  ngOnInit(): void {
    this.formContorl.valueChanges.subscribe((data)=>{
      if(data!=null && typeof(data) === "boolean"){
        this.changed.emit(data);
      }
    })
  }

}
