import { Component, EventEmitter, ChangeDetectorRef, Output, OnInit, ViewChild, ViewContainerRef, ElementRef } from '@angular/core';
import { HelpContactFormComponent } from './components/help-contact-form/help-contact-form.component';
import { HelpTextComponent } from './components/help-text/help-text.component';
import { HelpData } from './models/help-data';

@Component({
    selector: 'app-help',
    templateUrl: './help.component.html',
    styleUrls: ['./help.component.scss'],
    standalone: false
})
export class HelpComponent implements OnInit {

  @Output() onCloseModal = new EventEmitter();
  protected active3Column: any;

  @ViewChild("viewContainerRef", { read: ViewContainerRef }) vcr!: ViewContainerRef;


  private contactFormHrefLink = (name : string) => `<a target='_blank' style='font-weight: bold;text-decoration: none;color: #26A4D7;' class='help-text-link' style='' href='/home?contact=true'>${name}</a>`


  protected data : HelpData[] = [
    {
      name: "Access Rights",
      values: [
        {
          name: "Access Rights",
          active: false,
          component: {
            name: "",
            props: {}
          }
        },
        {
          name: "Add New Users",
          active: false,
          component: {
            name: HelpTextComponent,
            props: {
              text: `To add new users, click this ${this.contactFormHrefLink('link')} to create a new user request. We will guide you through the information needed for a new user set-up.`
            }
          }
        },
        {
          name: "Change Password",

          active: false,
          component: {
            name: HelpTextComponent,
            props: {

            }
          }
        },
        {
          name: "Change Username",
          active: false,
          component: {
            name: HelpTextComponent,
            props: {
            }
          }
        },
        {
          name: "Upgrade Access",
          active: false,
          component: {
            name: HelpTextComponent,
            props: {
              text: `To upgrade your access, click this ${this.contactFormHrefLink('link')} to inquire. We will guide you through the requirements needed to upgrade your account if applicable.`
            }
          }
        },
        {
          name: "View Other Locations/Countries",

          active: false,
          component: {
            name: HelpTextComponent,
            props: {
              text: `To request access to additional countries or regions, please click this ${this.contactFormHrefLink('link')} to make your request. We will review with your company's main account contact and identify next steps.`
            }
          }
        },
        {
          name: "Language Support",
          active: false,
          component: {
            name: HelpTextComponent,
            props: {
              text: "The SLS Link Portal uses English for all content and reports."
            }
          }
        },
      ],
      active: false,
    },
    {
      name: "Account Data",
      values: [
        {
          name: "Add/Edit Location Information",
          active: false,
          component: {
            name: HelpTextComponent,
            props: {
              text: `To revise location information, please click ${this.contactFormHrefLink('here')} to create your request. Our Onboarding Team will provide you with the requirements needed to make an adjustment.`
            }
          }
        },
        {
          name: "Add/Edit Billing Information",

          active: false,
          component: {
            name: HelpTextComponent,
            props: {
              text: `To revise billing information, please click ${this.contactFormHrefLink('here')} to create your request. Our Onboarding Team will provide you with the requirements needed to make an adjustment.`
            }
          }
        },
        {
          name: "Add/Edit Contact Information",

          active: false,
          component: {
            name: HelpTextComponent,
            props: {
              text: `To revise contact information, please click ${this.contactFormHrefLink('here')} to create your request. Our Onboarding Team will provide you with the requirements needed to make an adjustment.`
            }
          }
        },
        {
          name: "Divisions",

          active: false,
          component: {
            name: HelpTextComponent,
            props: {
              text: `Divisions in the Link Portal are based upon your company's divisions or groups. This allows SLS to segment elements for the programs you have in place to align with your company structure. If you see (Your Account Name + 'Division'), it means there is no division segmentation assigned. To update, click ${this.contactFormHrefLink('here')} to complete a request and our team will guide you through the requirements needed.`
            }
          }
        },
        {
          name: "Programs",

          active: false,
          component: {
            name: HelpTextComponent,
            props: {
              text: "Programs are defined by a group of services agreed at a commercial level."
            }
          }
        },
        {
          name: "Add/Edit Programs",
          active: false,
          component: {
            name: HelpTextComponent,
            props: {
              text: `Please click ${this.contactFormHrefLink('here')} to connect with your account representative.`
            }
          }
        },
        {
          name: "Sub-Programs",
          active: false,
          component: {
            name: HelpTextComponent,
            props: {
              text: "Sub-programs allow you to add another category for your orders."
            }
          }
        },
      ],
      active: false,
    },
    {
      name: "Notifications",
      values: [
        {
          name: "Notifications",

          active: false,
          component: {
            name: HelpTextComponent,
            props: {
            }
          }
        },
        {
          name: "Add/Edit Notifications",
          active: false,
          component: {
            name: HelpTextComponent,
            props: {
              text: `To adjust your notifications, please click ${this.contactFormHrefLink('here')} and our Onboarding Team will guide you through the information needed.`
            }
          }
        },
        {
          name: "Consolidate Notifications",
          active: false,
          component: {
            name: HelpTextComponent,
            props: {
              text: `To adjust your notifications, please click ${this.contactFormHrefLink('here')} and our Onboarding Team will guide you through the information needed.`
            }
          }
        },
      ],
      active: false,
    },
    {
      name: "Order Management",
      values: [
        {
          name: "Create an Order",

          active: false,
          component: {
            name: HelpTextComponent,
            props: {
            }
          }
        },
        {
          name: "Read an Order",

          active: false,
          component: {
            name: HelpTextComponent,
            props: {
            }
          }
        },
        {
          name: "Cancel or Edit Order",

          active: false,
          component: {
            name: HelpTextComponent,
            props: {
            }
          }
        },
        {
          name: "Order statuses",

          active: false,
          component: {
            name: HelpTextComponent,
            props: {
            }
          }
        },
        {
          name: "Order Missing From List",

          active: false,
          component: {
            name: HelpTextComponent,
            props: {
              text: `Your account has been configured as defined during onboarding. To request additional countries or regions, click ${this.contactFormHrefLink('here')}.`
            }
          }
        },
        {
          name: "Filter Orders",

          active: false,
          component: {
            name: HelpTextComponent,
            props: {
            }
          }
        },
        {
          name: "Reports for Orders",

          active: false,
          component: {
            name: HelpTextComponent,
            props: {
            }
          }
        },
        {
          name: "Order Pictures/Docs",

          active: false,
          component: {
            name: HelpTextComponent,
            props: {
            }
          }
        },
      ],
      active: false,
    },
    {
      name: "Reporting",
      values: [
        {
          name: "Report List",

          active: false,
          component: {
            name: HelpTextComponent,
            props: {
              text: `Reports vary by service level. To get an idea of the reports available, please click ${this.contactFormHrefLink('here')} to connect with your account representative.`
            }
          }
        },
        {
          name: "Data Sanitization Certificates",

          active: false,
          component: {
            name: HelpTextComponent,
            props: {
            }
          }
        },
        {
          name: "Read Reports",

          active: false,
          component: {
            name: HelpTextComponent,
            props: {
            }
          }
        },
        {
          name: "Request Custom Report",

          active: false,
          component: {
            name: HelpTextComponent,
            props: {
              text: `Please click ${this.contactFormHrefLink('here')} to connect with your account representative.`
            }
          }
        },
        {
          name: "Get My Reports",

          active: false,
          component: {
            name: HelpTextComponent,
            props: {
              text: "Orders will show when a specific order status has been received."
            }
          }
        },
      ],
      active: false,
    },
    {
      name: "Search",
      values: [
        {
          name: "What Can I Search For? Custom Search",

          active: false,
          component: {
            name: HelpTextComponent,
            props: {
            }
          }
        },
        {
          name: "Asset Search",

          active: false,
          component: {
            name: HelpTextComponent,
            props: {
            }
          }
        },
      ],
      active: false,
    },
    {
      name: "Services",
      values: [
        {
          name: "Add Service",

          active: false,
          component: {
            name: HelpTextComponent,
            props: {
              text: `Please click ${this.contactFormHrefLink('here')} to connect with your account representative.`
            }
          }
        },
        {
          name: "Available Services",

          active: false,
          component: {
            name: HelpTextComponent,
            props: {
              text: `Please click ${this.contactFormHrefLink('here')} to connect with your account representative.`
            }
          }
        },
      ],
      active: false,
    },
    {
      name: "Billing",
      values: [
        {
          name: "Invoices",

          active: false,
          component: {
            name: HelpTextComponent,
            props: {
            }
          }
        },
        {
          name: "Revenue Share Value",

          active: false,
          component: {
            name: HelpTextComponent,
            props: {
              text: ""
            }
          }
        },
        {
          name: "Change Billing",

          active: false,
          component: {
            name: HelpTextComponent,
            props: {
            }
          }
        },
      ],
      active: false,
    },
    {
      name: "Contact",
      values: [
        {
          name: "Account Rep",

          active: false,
          component: {
            name: HelpTextComponent,
            props: {
            }
          }
        },
        {
          name: "Contact form",

          active: false,
          component: {
            name: HelpContactFormComponent,
            props: {
            }
          }
        },
      ],
      active: false,
    },
  ]

  protected midData = [
    {
      name: '',
      active: false,
    }
  ];


  constructor(private changeDetector: ChangeDetectorRef) { }

  ngOnInit() {
    this.midData = [];
    this.active3Column = null;
  }
  onClickMidRow(index: number) {
    let leftValue = this.data.find(x => x.active);

    

    leftValue?.values.map((e, i) => {
      if (i === index) {
        e.active = !e.active;
        this.active3Column = e;
        this.vcr.clear();
        if (!!e.component.name) {
          let component = this.vcr.createComponent<HelpTextComponent | HelpContactFormComponent>(e.component?.name as any);
          if(component.instance instanceof HelpTextComponent){
            component.instance.text = (e.component.props as any).text;
          }
        }
      } else {
        e.active = false;
      }
    });
  }
  closeModal() {
    this.onCloseModal.emit();
  }
  onClickLeftRow(index: number) {
    this.data.map(e => {
      e.values.map(e => {
        e.active = false;
      })
      return e;
    })
    this.data = this.data.map((e, i) => {
      if (i === index) {
        e.active = !e.active;
        this.midData = e.active ? this.data[index].values : [];
      } else {
        e.active = false;
      }
      return e;
    });

  }
}
