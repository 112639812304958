import { Component, Input, OnInit } from '@angular/core';
import { NewsService } from '@app/shared/services/news.service';
import { News } from '../models/news.model';

@Component({
    selector: 'app-news-card',
    templateUrl: './news-card.component.html',
    styleUrls: ['./news-card.component.scss'],
    providers: [NewsService],
    standalone: false
})

export class NewsCardComponent implements OnInit {
  @Input() news: News;
  protected mediaUrl:string;
  protected showImg:boolean = false;

  constructor(private newsService: NewsService) {
  }

  ngOnInit(): void {
    this.mediaUrl = this.news.featured_image_src_square;
    if(this.mediaUrl.length >0) {
      this.showImg = true;
    }
  }
}
