import { Component, OnInit, Inject } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  AbstractControl,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CustomSelectOption } from '@app/shared/components/input/custom-select/models/custom-select-option';
import { CustomAutocompleteSearch } from '../input/custom-autocomplete/models/custom-autocomplete-search';

@Component({
    selector: 'app-filter-dialog',
    templateUrl: './filter-dialog.component.html',
    styleUrls: ['./filter-dialog.component.scss'],
    standalone: false
})
export class FilterDialogComponent implements OnInit {
  protected InputTypeEnum = InputType;
  protected filtrForm: FormGroup;
  protected firstColumnElements: number[];
  protected secondColumnElements: number[];
  protected touchedItems: string[] = [];
  constructor(
    public dialogRef: MatDialogRef<FilterDialogComponent>,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public filterSettings: FilterSettings
  ) {
    let fElements = Math.ceil(filterSettings.inputs.length / 2);
    this.firstColumnElements = [...Array(fElements).keys()];
    this.secondColumnElements = Array.from(
      { length: filterSettings.inputs.length - fElements },
      (v, k) => k + fElements
    );
  }

  ngOnInit() {
    this.filtrForm = this.formBuilder.group({});
    this.filterSettings.inputs.forEach((control) => {
      this.filtrForm.addControl(control.label, control.abstractControl);
    });
    Object.keys(this.filtrForm.controls).forEach((controlName) => {
      this.filtrForm.controls[controlName].valueChanges.subscribe((a) => {
        if (
          this.touchedItems.find((item) => item === controlName) === undefined
        ) {
          this.touchedItems.push(controlName);
        }
      });
    });
  }
  onApplyClick(): void {
    if (this.filtrForm.valid) {
      this.dialogRef.close(this.filtrForm);
    }
  }
  onClose() {
    this.touchedItems.forEach((e) => {
      this.filtrForm.get(e)?.reset();
    });
  }

  onFilterClearAll() {
    if (this.filterSettings.onClear) {
      this.filterSettings.onClear(this.filtrForm);
    } else {
      this.filtrForm.reset();
    }
  }

  getFormCustom(id: number): FormControl {
    return this.filterSettings.inputs[id].abstractControl as FormControl;
  }
  getFormGroup(id: number): FormGroup {
    return this.filterSettings.inputs[id].abstractControl as FormGroup;
  }
}

export class FilterSettings {
  title: string;
  inputs: CustomFormControl[];
  onClear?: (formGroup: FormGroup) => void;
}

export class CustomFormControl {
  public abstractControl: AbstractControl;
  public label: string;
  public inputType: InputType;
  public onAutoComplete?: CustomAutocompleteSearch;
  public selectOptions?: CustomSelectOption[];
  public inputName?: string;
}
export enum InputType {
  Input,
  InputAutocomplete,
  Select,
  Date,
  DateRange,
  Checkbox,
  MultiSelect,
}
