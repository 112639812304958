import { Component, ElementRef, OnInit } from '@angular/core';
import { CustomInboundOrderReportEnum } from '@app/core/enums/custom-inbound-order-report-enum';
import { InboundOrderReportTypeEnum } from '@app/core/enums/inbound-order-report-type-enum';
import { File } from '@app/core/models/file.model';
import { InboundOrdersService } from '@app/modules/orders/services/inbound-orders.service';
import { MakorOrderAPIService } from '@app/modules/orders/services/makor-order-api.service';
import { ReportSelectColumnComponent } from './report-select-column.component';
import { OrderReport } from '@app/modules/orders/models/order-report.model';
import { ColumnType } from '../column.type';
import { ColumnDef } from '../column';

export class OrderReportSelectColumnDef extends ColumnDef {
  constructor(
    header: string,
    fieldName: string,
    params: { id: number | string },
    hidden: boolean = false
  ) {
    super(ColumnType.OrderReportSelect, header, fieldName, hidden, params);
  }
}
@Component({
  selector: 'order-report-select-column',
  templateUrl: './report-select-column.component.html',
  styleUrls: ['./report-select-column.scss'],
  providers: [MakorOrderAPIService, InboundOrdersService],
  standalone: false,
})
export class OrderReportSelectColumnComponent
  extends ReportSelectColumnComponent
  implements OnInit
{
  private result: OrderReport[];

  constructor(
    elRef: ElementRef,
    private orderService: MakorOrderAPIService,
    private orderReportService: InboundOrdersService
  ) {
    super(elRef);
  }

  override loadList() {
    if (this.loaded) return;

    this.setLoadingList();
    this.orderReportService.getReportsList(this.id).subscribe((result) => {
      this.result = result;
      this.setDefaultList(
        result.map((x) => ({ id: x.actualName, text: x.displayName }))
      );
      this.loaded = true;
    });
  }

  override downloadReport() {
    let onDownloadSuccess = (result: File) => {
      this.downloading = false;
      result.fileName =
        this.options.find((e) => e.id === this.selectedOption)?.text ||
        result.fileName;
      this.downloadFile(result);
    };
    let onDownloadError = (error: any) => {
      console.log('Error', error);
      this.downloading = false;
    };
    this.downloading = true;
    let reportType = this.getReportType(this.selectedOption);

    if (reportType === InboundOrderReportTypeEnum.Makor) {
      this.orderService.getReport(this.id, this.selectedOption).subscribe(
        (result) => {
          onDownloadSuccess(result);
        },
        (error) => {
          onDownloadError(error);
        }
      );
    } else if (reportType === InboundOrderReportTypeEnum.Custom) {
      const enumValue =
        CustomInboundOrderReportEnum[
          this.selectedOption as keyof typeof CustomInboundOrderReportEnum
        ];
      this.orderService.getCustomReport(this.id, enumValue).subscribe(
        (result) => {
          onDownloadSuccess(result);
        },
        (error) => {
          onDownloadError(error);
        }
      );
    }
  }

  private getReportType(option: string) {
    return this.result.find((e) => e.actualName === option)?.reportType;
  }
}
