import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import * as uuid from 'uuid';
@Component({
    selector: 'app-custom-check-box',
    templateUrl: './custom-check-box.component.html',
    styleUrls: ['./custom-check-box.component.scss'],
    standalone: false
})
export class CustomCheckBoxComponent implements OnInit {

  constructor() { }
  @Input('formControl') formControl : FormControl;
  @Input('label') label : string;
  @Input('id') id : string = uuid.v4();
  @Input('disabled') disabled : boolean = false;

  protected hasErrors : boolean = false;
  ngOnInit() {
    this.formControl.valueChanges.subscribe((value)=>{
      this.hasErrors = false;
      if(!!this.formControl.errors){
         this.hasErrors = true;
      }
    });
  }

}
